.contact-note {
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: normal;
  padding: 0 15px;
  text-align: left;
}

.contact-message-box {
  width: 90%;
  margin: 0 auto;
  padding: 0px 15px 15px;
}

.contact-message-box .mdl-textfield {
  width: 100%;
}
