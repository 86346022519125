@import "_contactus.scss";

.mdl-layout {
  align-items: center;
  justify-content: center;
}

.mdl-layout__content {
  padding: 24px;
  flex: none;
}

.text-center {
  text-align: center;
}

.header-text-note {
  color: rgba(0, 0, 0, .95);
}

.forgot-text-note {
  color: rgba(0, 0, 0, .95);
  margin: 10px 0;
}

.login-error {
  color: #F44336;
  margin-bottom: 15px;
}

.captcha {
  margin: 0 auto;
}

.large-login-box {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.large-login-box .mdl-card {
  margin: 0 auto;
}

.large-login-box .jr-alert {
  margin: 0 auto;
  margin-bottom: 15px;
}

.section-header {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, .54);
  text-align: left !important;
  border-bottom: none !important;
  height: 20px;
  padding: 15px 0 0 15px !important;
}

.msg-captcha {
  padding: 15px 0;
}

.hidden-captcha-verify {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -999999999;
}
